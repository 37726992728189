import React, { useEffect, useState } from 'react';
import ToastMessage from '../../component/ToastMessage';
import { useResetPasswordMutation } from '../../queries/ResetPassword';
import './style.scss';
import { useParams, useNavigate } from 'react-router-dom';
import Button from '../../component/Button';
import { jwtDecode } from 'jwt-decode';

const ResetPassword: React.FC = () => {
  const [newPassword, setNewPassword] = useState<string>('');
  const [confirmPassword, setConfirmPassword] = useState<string>('');
  const [errors, setErrors] = useState<{ [key: string]: string }>({});
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false);

  const { token } = useParams<{ token: string }>();
  const navigate = useNavigate();

  useEffect(() => {
    try {
      if (token) {
        const decodedToken = jwtDecode(token);
        if (decodedToken !== undefined && decodedToken?.exp !== undefined) {
          if (decodedToken?.exp < Math.floor(Date.now() / 1000)) {
            ToastMessage({
              flag: 'Error',
              message: `Token expired. Please request again.`
            });
          }
        } else {
          ToastMessage({
            flag: 'Error',
            message: `Invalid token`
          });
        }
      }
    } catch ($error) {
      ToastMessage({
        flag: 'Error',
        message: `Invalid token`
      });
    }
  }, [token]);

  const validatePassword = () => {
    const error: { [key: string]: string } = {};

    if (!newPassword) {
      error.newPassword = 'New password is required.';
    } else if (newPassword.length < 6) {
      error.newPassword = 'Password must be at least 6 characters.';
    }

    if (!confirmPassword) {
      error.confirmPassword = 'Please confirm your password.';
    } else if (confirmPassword !== newPassword) {
      error.confirmPassword = 'Passwords do not match.';
    }

    setErrors(error);
    return Object.keys(error).length === 0;
  };

  const handlePasswordChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    if (name === 'newPassword') {
      setNewPassword(value);
    } else if (name === 'confirmPassword') {
      setConfirmPassword(value);
    }
    setErrors({});
  };

  const handleResetPasswordSuccess = (response: boolean) => {
    ToastMessage({
      flag: 'Success',
      message: 'Password reset successfully.'
    });
    setIsSubmitting(false);
    setNewPassword('');
    setConfirmPassword('');
    navigate('/');
  };

  const handleResetPasswordError = (error: any) => {
    if (
      error.name === 'TokenExpiredError' ||
      (typeof error.message === 'string' &&
        (error.message.toLowerCase().includes('expired') ||
          error.message.toLowerCase().includes('invalid token')))
    ) {
      ToastMessage({
        flag: 'Error',
        message: 'Token expired. Please request again.'
      });
    } else {
      ToastMessage({
        flag: 'Error',
        message: 'Failed to reset the password.'
      });
    }
    setIsSubmitting(false);
  };

  const { mutate: resetPasswordMutation } = useResetPasswordMutation(
    handleResetPasswordSuccess,
    handleResetPasswordError
  );

  const validateToken = () => {
    try {
      if (token) {
        const decodedToken = jwtDecode(token);
        if (decodedToken !== undefined && decodedToken?.exp !== undefined) {
          if (decodedToken?.exp > Math.floor(Date.now() / 1000)) {
            return true;
          } else {
            ToastMessage({
              flag: 'Error',
              message: `Token expired. Please request again.`
            });
            return false;
          }
        }
      }
      ToastMessage({
        flag: 'Error',
        message: `Invalid token`
      });
      return false;
    } catch ($error) {
      ToastMessage({
        flag: 'Error',
        message: `Invalid token`
      });
      return false;
    }
  };

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    if (token && validatePassword() && validateToken()) {
      setIsSubmitting(true);
      resetPasswordMutation({ jwtToken: token, password: newPassword });
    } else {
      if (!token) {
        ToastMessage({
          flag: 'Error',
          message: 'The jwtToken is invalid or missing.'
        });
      }
    }
  };
  return (
    <div className="reset-password-container">
      <h2>Reset Your Password</h2>
      <form onSubmit={handleSubmit}>
        <div className="input-text reset-password">
          <input
            type="password"
            name="newPassword"
            placeholder="New Password"
            value={newPassword}
            onChange={handlePasswordChange}
          />
          <div className="placeholder-text">New Password</div>
          {errors.newPassword && <p className="error-message">{errors.newPassword}</p>}
        </div>

        <div className="input-text reset-password">
          <input
            type="password"
            name="confirmPassword"
            placeholder="Confirm Password"
            value={confirmPassword}
            onChange={handlePasswordChange}
          />
          <div className="placeholder-text">Confirm Password</div>
          {errors.confirmPassword && <p className="error-message">{errors.confirmPassword}</p>}
        </div>

        <Button type="submit">{isSubmitting ? 'Saving...' : 'Save Password'}</Button>
      </form>
    </div>
  );
};

export default ResetPassword;
