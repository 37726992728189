import React from 'react';
import Routing from './routing';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import ReactGA from 'react-ga4';

const GA_MEASUREMENT_ID: any = process.env.REACT_APP_GA_TAG;

const App = () => {
  React.useEffect(() => {
    ReactGA.initialize(GA_MEASUREMENT_ID);
    ReactGA.send('pageview');
  }, []);

  return (
    <>
      <Routing />
      <ToastContainer
        closeOnClick={false}
        closeButton={false}
        autoClose={5000}
        position="top-right"
      />
    </>
  );
};
export default App;
