import * as React from 'react';
import { toast, ToastOptions } from 'react-toastify';
import { RxCross2 } from 'react-icons/rx';
import './style.scss';
const RxIcon: any = RxCross2;

type ToastFlag = 'Success' | 'Warning' | 'Error';

type ToastBodyProps = {
  closeToast: () => void;
  message: string;
  flag: string;
};
const ToastBody: React.FC<ToastBodyProps> = ({ closeToast, message, flag }) => {
  return (
    <div className="toast-box">
      <div className="closebtn" onClick={() => closeToast()}>
        <RxIcon />
      </div>
      <div className="inner-toast">
        <div className="logo-container">
          <span
            className={
              flag === 'Success'
                ? 'success-color'
                : flag === 'Warning'
                  ? 'warning-color'
                  : 'error-color'
            }
          >
            {flag}
          </span>
        </div>
        <div className="content-toast">
          <p className="toast-msg">{message}</p>
        </div>
      </div>
    </div>
  );
};

const getProgressColor = (flag: ToastFlag): string => {
  switch (flag) {
    case 'Success':
      return '#00875a';
    case 'Warning':
      return '#ff991f';
    case 'Error':
      return '#b22929';
    default:
      return '#ffffff';
  }
};

const ToastMessage = ({ message, flag }: { message: string; flag: ToastFlag }) => {
  toast(({ closeToast }) => <ToastBody flag={flag} message={message} closeToast={closeToast} />, {
    position: 'top-right',
    autoClose: 3000,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
    style: { borderLeft: `5px solid ${getProgressColor(flag)}` },
    progressStyle: { background: getProgressColor(flag) }
  } as ToastOptions);
};

export default ToastMessage;
