import React, { Suspense, lazy } from 'react';
import {
  Outlet,
  Route,
  createRoutesFromElements,
  isRouteErrorResponse,
  useRouteError
} from 'react-router-dom';
import { ProtectedRoute } from '../auth/ProtectedRoute';
import PrivateLayout from '../Layout/Private';
import NoFoundPage from '../pages/NoFound';
import ErrorComponent from '../pages/ErrorComponent';
import { ROUTES } from './routes';

const ProvidersLayout = () => {
  return (
    <ProtectedRoute>
      <Suspense>
        <Outlet />
      </Suspense>
    </ProtectedRoute>
  );
};

const PrivateRootErrorElement = () => {
  const error = useRouteError();

  if (isRouteErrorResponse(error)) {
    if (error.status === 404) {
      return <NoFoundPage />;
    }
    return <ErrorComponent />;
  }

  return null;
};

const MyProfile = lazy(() => import('../pages/MyProfile'));
const EditProfile = lazy(() => import('../pages/EditProfile'));
const BillingHistory = lazy(() => import('../pages/BillingHistory'));
const WashHistory = lazy(() => import('../pages/WashHistory'));
const ManagePlan = lazy(() => import('../pages/ManagePlan'));
const ContactSupport = lazy(() => import('../pages/ContactSupport'));
const MyReceipts = lazy(() => import('../pages/MyReceipts'));
const DownsellPromo = lazy(() => import('../pages/DownsellPromotion'));
const PurchasePlan = lazy(() => import('../pages/PurchasePlan/PurchasePlan'));
const MyPlans = lazy(() => import('../pages/MyPlans'));
const Cancel = lazy(() => import('../pages/CancelPlan'));

const protected_routes = createRoutesFromElements(
  <Route element={<ProvidersLayout />} errorElement={<PrivateRootErrorElement />}>
    <Route element={<PrivateLayout />}>
      <Route path={ROUTES.MYPROFILE} element={<MyProfile />} />
      <Route path={ROUTES.EDITPROFILE} element={<EditProfile />} />
      <Route path={ROUTES.BILLINGHISTORY} element={<BillingHistory />} />
      <Route path={ROUTES.WASHHISTORY} element={<WashHistory />} />
      <Route path={ROUTES.MANAGEPLAN} element={<ManagePlan />} />
      <Route path={ROUTES.CONTACTSUPPORT} element={<ContactSupport />} />
      <Route path={ROUTES.MYRECEIPT} element={<MyReceipts />} />
      <Route path={ROUTES.DOWNSELLPROMO} element={<DownsellPromo />} />
      <Route path={"/purchase-plan"} element={<PurchasePlan />} />
      <Route path={"/purchase-plan/"} element={<PurchasePlan />} />
      <Route path={"/purchase-plan/:slot_number"} element={<PurchasePlan />} />
      <Route path={ROUTES.MY_PLANS} element={<MyPlans />} />
      <Route path={ROUTES.CANCEL_PLAN} element={<Cancel />} />
    </Route>
  </Route>,
  [2]
);

export default protected_routes;
