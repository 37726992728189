import { GraphQLClient } from 'graphql-request';

const endpoint = process.env.REACT_APP_GRAPHQL_API_URL as string;
let token = localStorage.getItem('token');
let finalToken = `Bearer ${token}`;

export const Client = new GraphQLClient(endpoint, {
  credentials: 'include', // Needed for cookies
  mode: 'cors',
  headers: {
    'Content-Type': 'application/json',
    authorization: finalToken
  }
});
