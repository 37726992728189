import React from 'react';
import { Outlet } from 'react-router-dom';
import '../App.scss';
import SideBar from '../component/Sidebar';
const Private = () => {
  return (
    <div>
      <SideBar />
      <main className="main-layuot">
        <Outlet />
      </main>
    </div>
  );
};

export default Private;
