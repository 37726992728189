import { useMutation } from '@tanstack/react-query';
import { gql } from 'graphql-request';
import { Client } from '../Client';

const RESET_PASSWORD = gql`
  mutation reset_password($jwtToken: String!, $password: String!) {
    reset_password(jwtToken: $jwtToken, password: $password) {
      status
      message
    }
  }
`;

interface ResetPasswordVariables {
  jwtToken: string;
  password: string;
}

export const resetPassword = async ({
  jwtToken,
  password
}: ResetPasswordVariables): Promise<any> => {
  if (!jwtToken) {
    throw new Error('Invalid Token');
  }
  const data = await Client.request(RESET_PASSWORD, {
    jwtToken,
    password
  });
  return data;
};

export const useResetPasswordMutation = (
  onSuccessCallback: (data: boolean) => void,
  onErrorCallback: (error: any) => void
) => {
  return useMutation({
    mutationFn: resetPassword,
    onMutate: () => {},
    onError: (error) => {
      onErrorCallback(error);
    },
    onSuccess: (data) => {
      onSuccessCallback(data?.reset_password);
    },
    onSettled: () => {}
  });
};
