import React from 'react';
import './style.scss';
type Props = {
  children?: React.ReactNode;
};

const NoFound: React.FC<Props> = ({ children }) => {
  const logo = process.env.REACT_APP_LOGO_NAME;
  return (
    <div className="login-layout">
      <img src={`./${logo}-logo.png`} alt="logo" />
      <h2 className="no-found">404</h2>
    </div>
  );
};

export default NoFound;
