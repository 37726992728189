import React from 'react';
import { Outlet } from 'react-router-dom';
import '../App.scss';

interface PublicProps {
  children?: React.ReactNode;
}

const Public: React.FC<PublicProps> = () => {
  const logo = process.env.REACT_APP_LOGO_NAME;
  return (
    <div className="login-layout">
      <img src={`/${logo}-logo.png`} alt="logo" />
      <Outlet />
    </div>
  );
};

export default Public;
