import React, { Suspense, lazy } from 'react';
import {
  Outlet,
  Route,
  createRoutesFromElements,
  isRouteErrorResponse,
  useRouteError
} from 'react-router-dom';
import { CheckPublicRoute } from '../auth/CheckPublicRoute';
import Public from '../Layout/Public';
import NoFoundPage from '../pages/NoFound';
import ErrorComponent from '../pages/ErrorComponent';
import ForgotPassword from '../pages/ForgotPassword';
import ResetPassword from '../pages/ResetPassword';

const Login = lazy(() => import('../pages/Login'));
const FindFastPass = lazy(() => import('../pages/FindFastPass'));
const FindBarcode = lazy(() => import('../pages/FindBarcode'));
const Register = lazy(() => import('../pages/Register'));
const PurchasePlanVisitor = lazy(() => import('../pages/PurchasePlanVisitor/PurchasePlan'));

const ProvidersLayout = () => {
  return (
    <Suspense>
      <Outlet />
    </Suspense>
  );
};

const PublicRootErrorElement = () => {
  const error = useRouteError();

  if (isRouteErrorResponse(error)) {
    if (error.status === 404) {
      return <NoFoundPage />;
    }
    return <ErrorComponent />;
  }

  return null;
};

const public_routes = createRoutesFromElements(
  <Route element={<ProvidersLayout />} errorElement={<PublicRootErrorElement />}>
    <Route
      element={
        <CheckPublicRoute>
          <Public>
            <Suspense>
              <Outlet />
            </Suspense>
          </Public>
        </CheckPublicRoute>
      }
    >
      <Route path="/" element={<Login />} />
      <Route path="find-fast-pass" element={<FindFastPass />} />
      <Route path="find-barcode" element={<FindBarcode />} />
      <Route path="register" element={<Register />} />
      <Route path="forgot-password" element={<ForgotPassword />} />
      <Route path="reset-password/:token" element={<ResetPassword />} />
      <Route path="purchase-plan-visitor" element={<PurchasePlanVisitor />} />
      <Route path="purchase-plan-visitor/" element={<PurchasePlanVisitor />} />
      <Route path="purchase-plan-visitor/:slot_number" element={<PurchasePlanVisitor />} />
    </Route>
  </Route>,
  [1]
);
export default public_routes;
