import { createSlice, PayloadAction } from '@reduxjs/toolkit';

interface WashHistoryItem {
  id: number;
  title: string;
  date: string;
  icon: string;
}

interface BillingHistoryItem {
  id: number;
  title: string;
  date: string;
  icon: string;
}

interface HistoryState {
  washingHistory: WashHistoryItem[];
  billingHistory: BillingHistoryItem[];
}

const initialState: HistoryState = {
  washingHistory: [],
  billingHistory: []
};

const historySlice = createSlice({
  name: 'history',
  initialState,
  reducers: {
    setWashHistory(state, action: PayloadAction<WashHistoryItem[]>) {
      state.washingHistory = action.payload;
    },
    addWashHistoryItem(state, action: PayloadAction<WashHistoryItem>) {
      state.washingHistory.push(action.payload);
    },
    clearWashHistory(state) {
      state.washingHistory = [];
    },
    setBillingHistory(state, action: PayloadAction<BillingHistoryItem[]>) {
      state.billingHistory = action.payload;
    },
    addBillingHistoryItem(state, action: PayloadAction<BillingHistoryItem>) {
      state.billingHistory.push(action.payload);
    },
    clearBillingHistory(state) {
      state.billingHistory = [];
    }
  }
});

export const {
  setWashHistory,
  addWashHistoryItem,
  clearWashHistory,
  setBillingHistory,
  addBillingHistoryItem,
  clearBillingHistory
} = historySlice.actions;

export default historySlice.reducer;
