export const ROUTES = {
  MYPROFILE: '/my-profile',
  EDITPROFILE: '/my-profile/edit-profile/',
  BILLINGHISTORY: '/billing-history',
  WASHHISTORY: '/wash-history',
  MANAGEPLAN: '/manage-plan/:drb_cloud_code',
  CONTACTSUPPORT: '/contact-support',
  MYRECEIPT: '/billing-history/:id',
  DOWNSELLPROMO: '/downsell-promo/:drb_cloud_code',
  PURCHASEPLAN: '/purchase-plan',
  PURCHASEPLAN_VISITOR: '/purchase-plan-visitor',
  MY_PLANS: '/my-plans',
  CANCEL_PLAN: '/cancel-plan/:drb_cloud_code'
};
