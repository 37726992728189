import Cookies from 'js-cookie';
var HMACSHA256 = require('crypto-js').HmacSHA256;

// Define an interface for the User
export interface User {
  email?: string;
  lastName?: string;
  phone?: string;
}

export function parseJwt(token: any) {
  var base64Url = token.split('.')[1];
  var base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
  var jsonPayload = decodeURIComponent(
    atob(base64)
      .split('')
      .map(function (c) {
        return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
      })
      .join('')
  );

  return JSON.parse(jsonPayload);
}

export function replacer(key: string, value: any) {
  return value === undefined ? null : value;
}

export function toBase64(obj: any) {
  // converts the obj to a string
  const str = JSON.stringify(obj, replacer);

  // returns string converted to base64
  return btoa(str);
}

export function replaceSpecialChars(b64string: any) {
  // create a regex to match any of the characters =,+ or / and replace them with their // substitutes
  return b64string.replace(/[=+/]/g, (charToBeReplaced: any) => {
    switch (charToBeReplaced) {
      case '=':
        return '';
      case '+':
        return '-';
      case '/':
        return '_';
    }
  });
}

export function encodeJwt(barcode: any, lastName: any, creditCardNum: any, phone: any, email: any) {
  let encodedPayload: any = replaceSpecialChars(
    toBase64({
      barcode: barcode,
      lastName: lastName,
      creditCardNum: creditCardNum,
      phone: phone,
      email: email
    })
  );
  let encodedHeader: string = btoa(
    JSON.stringify({
      alg: 'HS256',
      typ: 'JWT'
    })
  );

  // create the signature part you have to take the encoded header,
  // the encoded payload, a secret, the algorithm specified in the header,
  // and sign that.
  const signature = HMACSHA256(`${encodedHeader}.${encodedPayload}`, 'CICRLEK');
  const encodedSignature = btoa(signature);

  const jwt = `${encodedHeader}.${encodedPayload}.${encodedSignature}`;
  return jwt;
}

// Function to get user details from the JWT stored in the cookie
export const getUserInfoFromCookie = (): string | null => {
  // Retrieve the JWT from the cookie
  const token = Cookies.get('ck-customer');

  if (!token) return null;

  try {
    return parseJwt(token);
  } catch (error) {
    console.error('Invalid or expired token', error);
    return null;
  }
};

// Function to remove the JWT from the cookie when logging out
export const removeLoginCookie = (): void => {
  Cookies.remove('ck-customer');
};
