import React from 'react';
import { Provider } from 'react-redux';
import { PersistGate } from 'redux-persist/integration/react';
import { RouterProvider, createBrowserRouter } from 'react-router-dom';
import { store, persistor } from '../store/store';
import public_routes from './PublicRoutes';
import protected_routes from './ProtectedRoutes';

const router = createBrowserRouter([...protected_routes, ...public_routes]);

const Routing = () => {
  return (
    <Provider store={store}>
      <PersistGate loading={null} persistor={persistor}>
        <RouterProvider router={router} />
      </PersistGate>
    </Provider>
  );
};
export default Routing;
