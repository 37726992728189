import React, { useState } from 'react';
import { useForgotPasswordMutation } from '../../queries/ForgotPassword';
import ToastMessage from '../../component/ToastMessage';
import './style.scss';
import Button from '../../component/Button';
const ForgotPassword: React.FC = () => {
  const [email, setEmail] = useState<string>('');
  const [errors, setErrors] = useState<{ email?: string }>({});

  const [isSubmitting, setIsSubmitting] = useState<boolean>(false);

  const handleForgotPasswordSuccess = (response: any) => {
    setIsSubmitting(false);
    if (response !== undefined) {
      if (response === true) {
        ToastMessage({
          flag: 'Success',
          message: 'Email sent successfully'
        });
      } else if (response === false) {
        ToastMessage({
          flag: 'Error',
          message: 'Something went wrong!'
        });
      }
    }
  };
  const handleForgotPasswordError = () => {};
  const {
    mutate: forgotPasswordMutation,
    isError: forgotPasswordError,
    isPending: isForgotPasswordPending
  } = useForgotPasswordMutation(handleForgotPasswordSuccess, handleForgotPasswordError);

  const handleEmailChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setEmail(e.target.value);
    setErrors({});
  };

  const validateEmail = () => {
    const error: { [key: string]: string } = {};

    if (!email) {
      error.email = 'Email is required.';
    } else if (!/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email)) {
      error.email = 'Invalid email format.';
    }

    setErrors(error);
    return Object.keys(error).length === 0;
  };

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    if (validateEmail()) {
      setIsSubmitting(true);
      forgotPasswordMutation(email);
      setEmail('');
      setErrors({});
    }
  };

  return (
    <form onSubmit={handleSubmit}>
      <div className="input-text">
        <input
          type="text"
          name="email"
          placeholder="Email"
          value={email}
          onChange={handleEmailChange}
        />
        {errors.email && <p className="error-message">{errors.email}</p>}
        <div className="placeholder-text">Email</div>
      </div>
      <div className="forgot-password-actions">
        <Button type="submit" altText="Update Logo">
          {isSubmitting ? 'Sending...' : 'Reset Password'}
        </Button>
      </div>
    </form>
  );
};

export default ForgotPassword;
