import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { removeLoginCookie, encodeJwt } from '../utils/auth';
import Cookies from 'js-cookie';

interface UserData {
  id?: number;
  firstName?: string;
  lastName?: string;
  email?: string;
  phone?: string;
  street_address?: string;
  zip?: number;
  customerId?: number;
  homeLocation?: string;
  barcode?: string;
  creditCardNum?: string;
  password?: string;
  birthday?: number;
  state_address?: string;
  fcm_token?: string;
  profile_image?: string;
  city?: string;
  __typename?: string;
}

interface UserState {
  isLoggedIn: boolean;
  userData: UserData | null;
  error: string | null;
}

const initialState: UserState = {
  isLoggedIn: false,
  userData: null,
  error: null
};

const userSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    logIn: (state, action: PayloadAction<UserData>) => {
      const {
        id,
        firstName,
        lastName,
        email,
        phone,
        street_address,
        zip,
        homeLocation,
        barcode,
        creditCardNum,
        birthday,
        state_address,
        fcm_token,
        city,
        __typename
      } = action.payload;

      state.isLoggedIn = true;
      const JWT = encodeJwt(barcode, lastName, creditCardNum, phone, email);

      if (id !== undefined) {
        Cookies.set('userId', id?.toString());
        sessionStorage.setItem('userId', id?.toString());
      }
      Cookies.set('ck-customer', JWT, { expires: 1 });
      state.userData = {
        id,
        birthday,
        ...(firstName ? { firstName } : {}),
        ...(lastName ? { lastName } : {}),
        ...(email ? { email } : {}),
        ...(phone ? { phone } : {}),
        ...(street_address ? { street_address } : {}),
        ...(zip ? { zip } : {}),
        ...(homeLocation ? { homeLocation } : {}),
        ...(barcode ? { barcode } : {}),
        ...(creditCardNum ? { creditCardNum } : {}),
        ...(state_address ? { state_address } : {}),
        ...(fcm_token ? { fcm_token } : {}),
        ...(city ? { city } : {}),
        ...(__typename ? { __typename } : {})
      };

      state.error = null;
    },
    setUserData: (state, action: PayloadAction<UserData | null>) => {
      state.userData = action.payload;
      if (action.payload) {
        state.isLoggedIn = true;
      } else {
        state.isLoggedIn = false;
      }
    },
    logOut: (state) => {
      state.isLoggedIn = false;
      state.userData = null;
      state.error = null;
      removeLoginCookie();
    },
    clearError: (state) => {
      state.error = null;
    },
    setError: (state, action: PayloadAction<string>) => {
      state.error = action.payload;
    },
    updateProfile: (state, action: PayloadAction<UserData>) => {
      if (state.userData) {
        state.userData = {
          ...state.userData,
          ...action.payload
        };
      }
    }
  }
});

export const { logIn, logOut, clearError, setError, updateProfile, setUserData } =
  userSlice.actions;
export default userSlice.reducer;
