import Cookies from 'js-cookie';
import React from 'react';
import { Navigate } from 'react-router-dom';

export const ProtectedRoute = ({ children }: { children: any }) => {
  const auth = Cookies.get('ck-customer');

  const renderPageNavigation = () => {
    return <Navigate to={`/`} />;
  };

  return auth ? children : renderPageNavigation();
};
