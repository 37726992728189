import { gql } from 'graphql-request';
import { Client } from '../Client';
import { useMutation } from '@tanstack/react-query';

const FORGOT_PASSWORD = gql`
  mutation forgot_password($email: String!) {
    forgot_password(email: $email)
  }
`;

export const forgotPasswordRequest = async (email: string): Promise<any> => {
  const data = await Client.request(FORGOT_PASSWORD, {
    email
  });
  return data;
};

export const useForgotPasswordMutation = (
  onSuccessCallback: (response: boolean | undefined) => void,
  onErrorCallback: (error: any) => void
) => {
  return useMutation({
    mutationFn: forgotPasswordRequest,
    onMutate: () => {},
    onError: (data) => {
      onErrorCallback(data);
    },
    onSuccess: (data) => {
      onSuccessCallback(data?.forgot_password);
    },
    onSettled: () => {}
  });
};
