import React, { useState, useEffect } from 'react';
import './style.scss';
import { Link, useNavigate, useLocation } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { logOut } from '../../store/userSlice';
import { RootState } from '../../store/store';
import { slide as Menu } from 'react-burger-menu';
import { FaUserAlt } from 'react-icons/fa';
import { BiReceipt } from 'react-icons/bi';
import { RiCarWashingLine } from 'react-icons/ri';
import { FaRegCalendarCheck } from 'react-icons/fa6';
import { GiWallet } from 'react-icons/gi';
import { FaPhoneAlt } from 'react-icons/fa';
import { FaSignOutAlt } from 'react-icons/fa';
import ReactGA from 'react-ga4';

type Props = {
  children?: React.ReactNode;
};

const SideBar: React.FC<Props> = ({ children }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const userData = useSelector((state: RootState) => state.user.userData);
  const [menuOpen, setMenuOpen] = useState(false);
  const [isMobile, setIsMobile] = useState(false);

  const FaUserAltIcon: any = FaUserAlt;
  const BiReceiptIcon: any = BiReceipt;
  const RiCarWashingLineIcon: any = RiCarWashingLine;
  const FaRegCalendarCheckIcon: any = FaRegCalendarCheck;
  const GiWalletIcon: any = GiWallet;
  const FaPhoneAltIcon: any = FaPhoneAlt;
  const FaSignOutAltIcon: any = FaSignOutAlt;

  useEffect(() => {
    const checkIfMobile = () => {
      const userAgent = typeof window.navigator === 'undefined' ? '' : navigator.userAgent;
      const mobileRegex = /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i;
      const isMobileDevice = mobileRegex.test(userAgent);
      const isSmallScreen = window.innerWidth < 768;
      setIsMobile(isMobileDevice || isSmallScreen);
    };

    checkIfMobile();
    window.addEventListener('resize', checkIfMobile);

    return () => {
      window.removeEventListener('resize', checkIfMobile);
    };
  }, []);

  const handleLogout = () => {
    dispatch(logOut());
    navigate('/');
  };

  const handleStateChange = (state: { isOpen: boolean }) => {
    setMenuOpen(state.isOpen);
  };

  const closeMenu = (event: any, page: string) => {
    ReactGA.event({
      category: 'User',
      action: 'Visit',
      label: page
    });
    setMenuOpen(false);
  };

  const logo = process.env.REACT_APP_LOGO_NAME;

  const isActive = (path: string) => {
    return location.pathname.startsWith(path) ? 'active' : '';
  };

  const renderMenuItems = () => (
    <>
      <div className="logo">
        <img src={`/${logo}-logo.png`} alt="logo" />
      </div>
      <ul>
        <Link to="/my-profile" onClick={(e) => closeMenu(e, 'my-profile')}>
          <li className={isActive('/my-profile')}>my profile</li>
        </Link>
        <Link to="/billing-history" onClick={(e) => closeMenu(e, 'billing-history')}>
          <li className={isActive('/billing-history')}>billing history</li>
        </Link>
        <Link to="/wash-history" onClick={(e) => closeMenu(e, 'wash-history')}>
          <li className={isActive('/wash-history')}>wash history</li>
        </Link>
        <Link to="/my-plans" onClick={(e) => closeMenu(e, 'my-plans')}>
          <li className={isActive('/my-plans')}>my plans</li>
        </Link>
        {userData?.barcode ? (
          <li className="disabled-plan">purchase plan</li>
        ) : (
          <Link to="/purchase-plan" onClick={(e) => closeMenu(e, 'purchase-plan')}>
            <li className={isActive('/purchase-plan')}>purchase plan</li>
          </Link>
        )}
        <Link to="/contact-support" onClick={(e) => closeMenu(e, 'contact')}>
          <li className={isActive('/contact-support')}>contact support</li>
        </Link>
        <Link
          to={process.env.REACT_APP_TERMS_LINK as string}
          onClick={(e) => closeMenu(e, 'terms')}
        >
          <li className={isActive(process.env.REACT_APP_TERMS_LINK as string)}>
            Terms and conditions
          </li>
        </Link>
        <li
          onClick={() => {
            handleLogout();
            closeMenu(undefined, 'logout');
          }}
          style={{ cursor: 'pointer' }}
        >
          log out
        </li>
      </ul>
    </>
  );

  const renderMobileIcons = () => (
    <div className="mobile-icons-sidebar">
      <div className="icon-item">
        <Link to="/my-profile" onClick={(e) => closeMenu(e, 'my-profile')}>
          <FaUserAltIcon size={20} />
        </Link>
      </div>
      <div className="icon-item">
        <Link to="/billing-history" onClick={(e) => closeMenu(e, 'billing-history')}>
          <BiReceiptIcon size={20} />
        </Link>
      </div>
      <div className="icon-item">
        <Link to="/wash-history" onClick={(e) => closeMenu(e, 'wash-history')}>
          <RiCarWashingLineIcon size={20} />
        </Link>
      </div>
      <div className="icon-item">
        <Link to="/my-plans" onClick={(e) => closeMenu(e, 'my-plans')}>
          <FaRegCalendarCheckIcon size={20} />
        </Link>
      </div>
      <div className="icon-item">
        <Link to="/purchase-plan" onClick={(e) => closeMenu(e, 'purchase-plan')}>
          <GiWalletIcon size={20} />
        </Link>
      </div>
      <div className="icon-item">
        <Link to="/contact-support" onClick={(e) => closeMenu(e, 'contact')}>
          <FaPhoneAltIcon size={20} />
        </Link>
      </div>
      <div className="icon-item">
        <Link
          to={process.env.REACT_APP_TERMS_LINK as string}
          onClick={(e) => closeMenu(e, 'terms')}
        >
          <li className={isActive(process.env.REACT_APP_TERMS_LINK as string)}>
            Terms and conditions
          </li>
        </Link>
      </div>
      <div className="icon-item">
        <div onClick={handleLogout}>
          <FaSignOutAltIcon size={20} />
        </div>
      </div>
    </div>
  );

  return isMobile ? (
    <div className="sidebar-mobile-container">
      <Menu
        isOpen={menuOpen}
        onStateChange={handleStateChange}
        width={'250px'}
        burgerButtonClassName="burger-button"
        burgerBarClassName="burger-bars"
        crossButtonClassName="cross-button"
        crossClassName="cross"
        menuClassName="bm-menu"
        morphShapeClassName="bm-morph-shape"
        itemListClassName="bm-item-list"
        overlayClassName="bm-overlay"
      >
        {renderMenuItems()}
      </Menu>

      {/* Temporarily disabled */}
      {/*!menuOpen && renderMobileIcons()*/}

      <div className={`content-wrapper ${menuOpen ? 'menu-open' : ''}`}>{children}</div>
    </div>
  ) : (
    <div className="sidebar">{renderMenuItems()}</div>
  );
};

export default SideBar;
