import { createSlice, PayloadAction } from '@reduxjs/toolkit';

interface Plan {
  PlanId: number | null;
  ItemName: string;
  Amount: number | null;
  SlotNumber: number | null;
}

interface Vehicle {
  year: number | null;
  make: string;
  model: string;
  color: string;
  picture: string | null;
  vehicleSummary: string;
}

interface Payment {
  cc_number: string;
  exp_month: number | string | null;
  exp_year: number | null;
  security_code: number | null;
}

interface User {
  id?: number;
  firstName?: string;
  lastName?: string;
  email?: string;
  phone?: string;
  street?: string;
  zipcode?: number;
  state?: string;
  homeLocation?: string;
  barcode?: string;
  creditCardNum?: string;
  password?: string;
  birthday?: number;
  state_address?: string;
  fcm_token?: string;
  city?: string;
  __typename?: string;
}
interface PlanState {
  visitorInfo: User;
  selectedPlans: Plan;
  vehicleInfo: Vehicle;
  payment: Payment;
}

const initialState: PlanState = {
  visitorInfo: {
    id: 0,
    firstName: '',
    lastName: '',
    email: '',
    phone: '',
    street: '',
    zipcode: 0,
    homeLocation: '',
    barcode: '',
    state: '',
    creditCardNum: '',
    password: '',
    birthday: 0,
    state_address: '',
    fcm_token: '',
    city: '',
    __typename: ''
  },
  selectedPlans: {
    PlanId: null,
    ItemName: '',
    Amount: null,
    SlotNumber: null
  },
  vehicleInfo: {
    year: null,
    make: '',
    model: '',
    color: '',
    picture: null,
    vehicleSummary: ''
  },
  payment: {
    cc_number: '',
    exp_year: null,
    exp_month: null,
    security_code: null
  }
};

const planSlice = createSlice({
  name: 'plan',
  initialState,
  reducers: {
    purchasePlan(state, action: PayloadAction<Plan>) {
      const { PlanId } = action.payload;
      if (state.selectedPlans.PlanId === PlanId) {
        state.selectedPlans = {
          PlanId: null,
          ItemName: '',
          Amount: null,
          SlotNumber: null
        };
      } else {
        state.selectedPlans = action.payload;
      }
    },
    purchasePlanVisitor(state, action: PayloadAction<Plan>) {
      const { PlanId } = action.payload;
      if (state.selectedPlans.PlanId === PlanId) {
        state.selectedPlans = {
          PlanId: null,
          ItemName: '',
          Amount: null,
          SlotNumber: null
        };
      } else {
        state.selectedPlans = action.payload;
      }
    },
    setVehicleDetails(state, action: PayloadAction<Partial<Vehicle>>) {
      state.vehicleInfo = { ...state.vehicleInfo, ...action.payload };
    },

    setPaymentDetails(state, action: PayloadAction<Payment>) {
      state.payment = action.payload;
    },
    setVisitorDetails(state, action: PayloadAction<User>) {
      state.visitorInfo = action.payload;
    },
    clearPlanState(state) {
      state.selectedPlans = {
        PlanId: null,
        ItemName: '',
        Amount: null,
        SlotNumber: null
      };
      state.vehicleInfo = {
        year: null,
        make: '',
        model: '',
        color: '',
        picture: null,
        vehicleSummary: ''
      };
      state.payment = {
        cc_number: '',
        exp_year: null,
        exp_month: null,
        security_code: null
      };
    }
  }
});

export const {
  purchasePlan,
  setVehicleDetails,
  setPaymentDetails,
  clearPlanState,
  purchasePlanVisitor,
  setVisitorDetails
} = planSlice.actions;

export default planSlice.reducer;
