import { configureStore } from '@reduxjs/toolkit';
import { persistStore, persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import userReducer from './userSlice';
import historyReducer from './historySlice';
import planReducer from './planSlice';

const persistConfig = {
  key: 'root',
  storage
};

const persistedUserReducer = persistReducer(persistConfig, userReducer);
const persistedHistoryReducer = persistReducer(persistConfig, historyReducer);
const persistedPlanReducer = persistReducer(persistConfig, planReducer);

const store = configureStore({
  reducer: {
    user: persistedUserReducer,
    history: persistedHistoryReducer,
    plan: persistedPlanReducer
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: {
        ignoredActions: ['persist/PERSIST', 'persist/FLUSH', 'persist/REHYDRATE', 'persist/REMOVE']
      }
    })
});

const persistor = persistStore(store);
export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;

export { store, persistor };
